import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
// import ProtoBufApi from '@/services/protobuf-api'
// import AuditTrailApi from '@/services/audittrail-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(id) {
    const result = await FqApi.get(`/receiptpayment?id=${id}`)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseReceiptEntryFilter(filter)
    const url = `/receiptentry/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseReceiptEntryFilter(filter) {
    const receiptEntryProto = require('../../assets/proto/receiptentry/ReceiptEntryFilter.proto')
    const filterMessage = receiptEntryProto.ReceiptEntryFilter.ReceiptEntryFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getGLBankAccountsDropdownProto(id) {
    const result = await FqApi.get(`/receiptentry/getGlBankAccount?id=${id}`)
    return result.data
  },
  async GetNewCreditorInvoice(id) {
    const result = await FqApi.get(`/receiptentry/factorinvoice?id=${id}`)
    return result.data
  }
}