import { TableColumn } from '@/classes'

const Columns = [
  //new TableColumn('Check', 'isChecked', false, 'asc', 'centered'),
  new TableColumn('Invoice No', 'invoiceRef', true, 'asc', 'left'),
  new TableColumn('Debtor Name', 'customerName', true, 'asc', 'left'),
  new TableColumn('Inv Date', 'invoiceDate', true, 'asc', 'left'),
  new TableColumn('Balance', 'balance', true, 'asc', 'right'),
  new TableColumn('Inv Excl. GST', 'amount', false, 'asc', 'right'),
  new TableColumn('GST', 'gstAmount', false, 'asc', 'right'),
  new TableColumn('Inv Amount', 'totalAmount', false, 'asc', 'right'),
  new TableColumn('Quote No', 'quoteNo', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const ReceiptPaymentColumns = [
  new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Invoice No', 'invoiceRef', true, 'asc', 'left'),
  new TableColumn('Invoice Date', 'invoiceDate', true, 'asc', 'left'),
  new TableColumn('Balance', 'balance', true, 'asc', 'left'),
  new TableColumn('Recd Amount', 'recordAmount', false, 'asc', 'centered'),
  new TableColumn('Recd Date', 'receivedDate', false, 'asc', 'centered'),
  new TableColumn('Cheque No', 'chequeNo', false, 'asc', 'centered')
]
export { Columns,ReceiptPaymentColumns }