<template>
  <div v-if="$userInfo">

    <router-view />
  </div>
</template>

<script>

export default {
  name: 'ReceiptEntryContainerView',
  components: {

  }
}
</script>