/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ReceiptEntryFilter = (function() {

    /**
     * Namespace ReceiptEntryFilter.
     * @exports ReceiptEntryFilter
     * @namespace
     */
    var ReceiptEntryFilter = {};

    ReceiptEntryFilter.ReceiptEntryFilterModel = (function() {

        /**
         * Properties of a ReceiptEntryFilterModel.
         * @memberof ReceiptEntryFilter
         * @interface IReceiptEntryFilterModel
         * @property {string|null} [invoiceNo] ReceiptEntryFilterModel invoiceNo
         * @property {string|null} [debtor] ReceiptEntryFilterModel debtor
         * @property {string|null} [quoteNo] ReceiptEntryFilterModel quoteNo
         * @property {number|null} [filterType] ReceiptEntryFilterModel filterType
         * @property {number|null} [includeAdvance] ReceiptEntryFilterModel includeAdvance
         * @property {string|null} [sortColumn] ReceiptEntryFilterModel sortColumn
         * @property {string|null} [sortOrder] ReceiptEntryFilterModel sortOrder
         * @property {number|null} [pageIndex] ReceiptEntryFilterModel pageIndex
         * @property {number|null} [pageSize] ReceiptEntryFilterModel pageSize
         */

        /**
         * Constructs a new ReceiptEntryFilterModel.
         * @memberof ReceiptEntryFilter
         * @classdesc Represents a ReceiptEntryFilterModel.
         * @implements IReceiptEntryFilterModel
         * @constructor
         * @param {ReceiptEntryFilter.IReceiptEntryFilterModel=} [p] Properties to set
         */
        function ReceiptEntryFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiptEntryFilterModel invoiceNo.
         * @member {string|null|undefined} invoiceNo
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.invoiceNo = null;

        /**
         * ReceiptEntryFilterModel debtor.
         * @member {string|null|undefined} debtor
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.debtor = null;

        /**
         * ReceiptEntryFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.quoteNo = null;

        /**
         * ReceiptEntryFilterModel filterType.
         * @member {number|null|undefined} filterType
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.filterType = null;

        /**
         * ReceiptEntryFilterModel includeAdvance.
         * @member {number|null|undefined} includeAdvance
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.includeAdvance = null;

        /**
         * ReceiptEntryFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.sortColumn = null;

        /**
         * ReceiptEntryFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.sortOrder = null;

        /**
         * ReceiptEntryFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.pageIndex = null;

        /**
         * ReceiptEntryFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        ReceiptEntryFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ReceiptEntryFilterModel _invoiceNo.
         * @member {"invoiceNo"|undefined} _invoiceNo
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_invoiceNo", {
            get: $util.oneOfGetter($oneOfFields = ["invoiceNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _debtor.
         * @member {"debtor"|undefined} _debtor
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_debtor", {
            get: $util.oneOfGetter($oneOfFields = ["debtor"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _filterType.
         * @member {"filterType"|undefined} _filterType
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_filterType", {
            get: $util.oneOfGetter($oneOfFields = ["filterType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _includeAdvance.
         * @member {"includeAdvance"|undefined} _includeAdvance
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_includeAdvance", {
            get: $util.oneOfGetter($oneOfFields = ["includeAdvance"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ReceiptEntryFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @instance
         */
        Object.defineProperty(ReceiptEntryFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ReceiptEntryFilterModel message. Does not implicitly {@link ReceiptEntryFilter.ReceiptEntryFilterModel.verify|verify} messages.
         * @function encode
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @static
         * @param {ReceiptEntryFilter.IReceiptEntryFilterModel} m ReceiptEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiptEntryFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.invoiceNo != null && Object.hasOwnProperty.call(m, "invoiceNo"))
                w.uint32(10).string(m.invoiceNo);
            if (m.debtor != null && Object.hasOwnProperty.call(m, "debtor"))
                w.uint32(18).string(m.debtor);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(26).string(m.quoteNo);
            if (m.filterType != null && Object.hasOwnProperty.call(m, "filterType"))
                w.uint32(32).int32(m.filterType);
            if (m.includeAdvance != null && Object.hasOwnProperty.call(m, "includeAdvance"))
                w.uint32(40).int32(m.includeAdvance);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified ReceiptEntryFilterModel message, length delimited. Does not implicitly {@link ReceiptEntryFilter.ReceiptEntryFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @static
         * @param {ReceiptEntryFilter.IReceiptEntryFilterModel} message ReceiptEntryFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiptEntryFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiptEntryFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ReceiptEntryFilter.ReceiptEntryFilterModel} ReceiptEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiptEntryFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ReceiptEntryFilter.ReceiptEntryFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.invoiceNo = r.string();
                    break;
                case 2:
                    m.debtor = r.string();
                    break;
                case 3:
                    m.quoteNo = r.string();
                    break;
                case 4:
                    m.filterType = r.int32();
                    break;
                case 5:
                    m.includeAdvance = r.int32();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a ReceiptEntryFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ReceiptEntryFilter.ReceiptEntryFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ReceiptEntryFilter.ReceiptEntryFilterModel} ReceiptEntryFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiptEntryFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return ReceiptEntryFilterModel;
    })();

    return ReceiptEntryFilter;
})();

module.exports = $root;
